import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { BehaviorSubject, EMPTY, fromEvent, map, race, Subject, take, takeUntil, skip, of, tap } from 'rxjs';
import { UniversalService } from './universal.service.js';

@Injectable({
  providedIn: 'root',
})
export class UserInteractOnBrowserService implements OnDestroy {
  scrollY = new BehaviorSubject(0);
  scrollY$ = this.scrollY.asObservable();
  destroy$ = new Subject();
  interacted = false;
  constructor(public universalService: UniversalService) { }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  onInteract() {
    if (this.universalService.isBrowser()) {
      if (!this.interacted) {
        return race(
          fromEvent(window, 'scroll').pipe(skip(1)),
          fromEvent(window, 'pointermove'),
          fromEvent(window, 'pointerdown'),
        ).pipe(takeUntil(this.destroy$), take(1), tap(() => this.interacted = true));
      } else {
        return of<any>(true)
      }
    } else {
      return EMPTY;
    }
  }

  onFirstScroll() {
    if (this.universalService.isBrowser()) {
      return this.listenWindowScroll().pipe(take(1));
    } else {
      return EMPTY;
    }
  }

  private listenWindowScroll() {
    return fromEvent(window, 'scroll').pipe(map((e: Event) => this.getYPosition(e), take(1)));
  }

  updateScrollY(value: number): void {
    this.scrollY.next(value);
  }

  getScrollingElement(): Element {
    return document.scrollingElement || document.documentElement;
  }

  private getYPosition(e: Event): number {
    return (e.target as Element).scrollTop;
  }
}
// https://itnext.io/4-ways-to-listen-to-page-scrolling-for-dynamic-ui-in-angular-ft-rxjs-5a83f91ee487

// @HostListener('window:scroll') onScroll(e: Event): void {
// 	    this.scrollY.next(this.getYPosition(e));
// 	}

// -----Useful: Get the maximum scroll height
// This is useful for functionality that relies on the computed percentage of the content that has been scrolled. Such as those mentioned above. Here we would need to take the maximum value from these values to obtain the most accurate value for the scrollable height.

// getScrollHeight(): number {
//   return Math.max(
//     document.body.scrollHeight, document.documentElement.scrollHeight,
//     document.body.offsetHeight, document.documentElement.offsetHeight,
//     document.body.clientHeight, document.documentElement.clientHeight
//   );
// }

// Listening and reacting to scrollY updates
// Reacting to scroll position updates is as simple as listening to the scrollY$ observable and executing code based on the value emitted. For example, calculating the percentage of the content scrolled, which would be..
// scrollY - scrollHeight / 100
// You’d probably want to round the value with…
// Math.round(scrollY - scrollHeight / 100)

// TODO: need scroll process
